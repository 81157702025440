<template>
  <div>
    <p style="font-size: 20px; font-weight: 600; margin: 20px 0 20px 0">
      超时未接单排行榜
    </p>
    <!-- 店长店铺城市选择 -->
    <div style="display: flex; justify-content: start; margin: 20px 0 0 5px">
      <el-select
        :clearable="true"
        v-model="city"
        placeholder="选择城市"
        class="littleBox inputStyle"
        @change="choseCity1(city)"
        filterable
      >
        <el-option
          v-for="(item, index) in cities"
          :key="index"
          :label="item.city"
          :value="item.city_id"
        ></el-option>
      </el-select>
      <el-select
        :clearable="true"
        v-if="city != ''"
        v-model="shopper"
        filterable
        placeholder="选择店长"
        class="littleBox rankStyle inputStyle"
        @change="choseShopkeeper1(shopper)"
      >
        <el-option
          v-for="(item, index) in shoppers"
          :key="index"
          :label="item.username"
          :value="item.user_id"
        ></el-option>
      </el-select>
      <el-select
        :clearable="true"
        v-if="city != ''"
        v-model="store"
        filterable
        placeholder="选择店铺"
        class="littleBox rankStyle inputStyle"
        @change="choseStore1(store)"
      >
        <el-option
          v-for="(item, index) in stores"
          :key="index"
          :label="item.store_name"
          :value="item.store_name"
        ></el-option>
      </el-select>
      <div>
        <span style="margin: 0 10px 0 20px; font-size: 14px">下单时间:</span>
        <el-date-picker
          v-model="create_time1"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          class="datePicker"
          :default-time="['00:00:00', '23:59:59']"
        ></el-date-picker>
      </div>
      <el-button
        type="primary"
        size="small"
        plain
        @click="getOverList"
        style="margin-left: 20px"
        >筛选</el-button
      >
    </div>
    <!-- <div style="display: flex; justify-content: start; margin-top: 20px;font-size:13px;position:absolute;top:200px;margin-left:-20px;">
        <div :time1="1" @click="rankTime1(1)" class="rankStyle" :class="[time1==1?'selectedTime':'']" style="cursor: pointer;">按照下单时间排序<i class="el-icon-top"></i></div>
        <div :time1="2" @click="rankTime1(2)" :class="[time1==2?'selectedTime':'']" style="cursor: pointer;">按照超时时间排序<i class="el-icon-top"></i></div>
      </div>-->
    <avue-crud
      :data="tableData1"
      :option="option1"
      v-model="obj1"
      style="margin-top: 15px"
    >
      <template slot="overtime" slot-scope="scope">
        <div>{{ scope.row.overtime }}分钟</div>
      </template>
    </avue-crud>
    <div class="flex_start mt20 flex_items">
      <span class="demonstration mr20">
        总共
        <span style="color: red">{{ count1 }}</span
        >条数据,当前 <span style="color: red">{{ tableData1.length }}</span
        >条
      </span>
      <el-pagination
        @current-change="handleCurrentChange1"
        :current-page.sync="currentPage1"
        :page-size="20"
        :total="count1"
        layout="prev, pager, next, jumper"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  //   props: {
  //     mobile: Number
  //   },
  data() {
    return {
      city: "",
      cities: [],
      shopper: "",
      shoppers: [],
      store: "",
      stores: [],
      create_time1: "",
      count1: 0,
      tableData1: [],
      option1: {
        border: true,
        size: "medium",
        menu: false,
        page: false,
        align: "left",
        dateBtn: false,
        dateDefault: false,
        addBtn: false,
        columnBtn: false, //列显隐按钮
        refreshBtn: false, //刷新按钮
        //   delBtn:false,
        //   editBtn:false,
        column: [
          {
            label: "店长",
            prop: "username",
            width: "150px",
          },
          {
            label: "店铺",
            prop: "store_name",
            width: "100px",
          },
          {
            label: "订单号",
            prop: "order_id",
          },
          {
            label: "工地地址",
            prop: "addres",
          },
          {
            label: "客户姓名",
            prop: "contacts",
            width: "100px",
          },
          {
            label: "客户电话",
            prop: "telephone",
            width: "130px",
          },
          {
            label: "下单时间",
            prop: "created_time",
            type: "date",
            format: "yyyy-MM-dd hh:mm:ss",
            valueFormat: "yyyy-MM-dd hh:mm:ss",
            sortable: true,
          },
          {
            label: "派单时间",
            prop: "writetime",
            type: "date",
            format: "yyyy-MM-dd hh:mm:ss",
            valueFormat: "yyyy-MM-dd hh:mm:ss",
          },
          {
            label: "超时时间",
            prop: "overtime",
            sortable: true,
          },
        ],
      },
      obj1: {},
      currentPage1: 1,
      time1: 0,
    };
  },
  created() {
    this.getCity();
    this.getOverList();
  },
  watch: {},
  methods: {
    getCity() {
      //获取城市
      this.util.get(this.HOST + "/Common/cheng", {}).then((res) => {
        if (res.code == 200) {
          this.cities = res.data;
          this.cities5 = res.data;
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
            duration: 1200,
            offset: 90,
          });
        }
      });
    },
    choseCity1(city) {
      //选择城市-超时未接单
      this.shopper = "";
      this.store = "";
      this.getShopper(city);
      this.getStores(city);
      this.getOverList();
    },
    choseShopkeeper1(shopper) {
      //选择店长之后的操作-超时未接单
      this.getOverList();
    },
    choseStore1(value) {
      //选择店铺之后-超时未接单
      this.getOverList();
    },
    getShopper(city) {
      //获取店长
      this.util
        .get(this.HOST + "/Common/user", {
          city_id: city,
        })
        .then((res) => {
          if (res.code == 200) {
            this.shoppers = res.data;
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    getStores(city) {
      //获取选取城市的店铺
      this.util
        .get(this.HOST + "/Common/store", {
          city_id: city,
        })
        .then((res) => {
          //console.log(res, "stores++++");
          if (res.code == 200) {
            this.stores = res.data;
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    getOverList() {
      //超时未接单排行榜列表
      if (this.create_time1 == null || this.create_time1 == "") {
        this.create_time1 = "";
      }
      let time_start = this.util.datatime(this.create_time1[0]);
      let time_end = this.util.datatime(this.create_time1[1]);
      if (time_start == "NaN-NaN-NaN NaN:NaN:NaN") {
        time_start = "";
        time_end = "";
      }
      this.$loading(this.util.opload);
      this.util
        .get(this.HOST + "/Order/overtime", {
          page: 1,
          limit: 20,
          user_id: this.shopper,
          store_id: this.store,
          city_id: this.city,
          desc: this.time1,
          time_start: time_start,
          time_end: time_end,
        })
        .then((res) => {
          if (res.code == 200) {
            this.count1 = res.data.count;
            for (let i = 0; i < res.data.list.length; i++) {
              res.data.list[i].clock_in_time = this.util.timestampToTime(
                res.data.list[i].clock_in_time
              );
            }
            this.tableData1 = res.data.list;
            this.$loading().close();
          } else {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    handleCurrentChange1(val) {
      if (this.create_time1 == null || this.create_time1 == "") {
        this.create_time1 = "";
      }
      let time_start = this.util.datatime(this.create_time1[0]);
      let time_end = this.util.datatime(this.create_time1[1]);
      if (time_start == "NaN-NaN-NaN NaN:NaN:NaN") {
        time_start = "";
        time_end = "";
      }
      this.$loading(this.util.opload);
      this.tableData1 = [];
      this.util
        .get(this.HOST + "/Order/overtime", {
          limit: 20,
          user_id: "",
          store_id: "",
          city_id: "",
          page: this.currentPage1,
          desc: this.time1,
          time_start: time_start,
          time_end: time_end,
        })
        .then((res) => {
          if (res.code == 200) {
            this.count1 = res.data.count;
            this.tableData1 = res.data.list;
            this.$loading().close();
          } else {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
  },
};
</script>
<style scoped>
.content {
  margin: 0 auto;
  background: white;
  padding: 10px 0 0 30px;
  border-radius: 8px;
  height: 100%;
}
.child {
  width: 24%;
  margin-bottom: 10%;
}
.number {
  font-size: 26px;
  /* text-align: center; */
  color: #999;
  margin: 5% 0 0 25%;
}
.title {
  font-size: 16px;
  margin-top: 20px;
  text-align: left;
  color: #666;
  width: 145px;
}
.sort {
  margin-bottom: -20px;
}
.rankStyle {
  margin-left: 20px;
}
.selectedTime {
  color: #409eff;
}
.returnIcon:hover {
  color: cornflowerblue;
}
div /deep/ .avue-crud__menu {
  min-height: 0px !important;
}
.el-icon-warning:hover span {
  display: block;
}
::v-deep .datePicker {
  width: 256px;
  height: 32px !important;
  line-height: 32px !important;
}
::v-deep .littleBox input {
  height: 32px !important;
  line-height: 32px !important;
  width: 160px;
}
</style>
